<template>
  <error-report-component />
</template>

<script>
const ErrorReportComponent = () => import('@/components/Machines/ErrorReport')

export default {
  name: 'ErrorReportView',
  components: {
    ErrorReportComponent
  }
}
</script>
